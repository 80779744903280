<template>
  <CRow>
    <CCol md="12">
      <CCard class="clapans">
        <CCardHeader>
          {{ alert_message }}<br>
          <div class="d-flex aligin-items-center justify-content-between">
            <div class="input-group mb-3">
              <div class="form-file">
                <input type="file" class="form-file-input" id="inputGroupFile01"
                       v-on:change="onFileChange"
                       aria-describedby="inputGroupFileAddon01">
              </div>
            </div>
            <CButton color="success" @click="convertToArray()">
              Добавить
            </CButton>
          </div>
        </CCardHeader>
        <CCardBody>
          <CDataTable
              class="mb-0 table-outline"
              hover
              :items="tableItems"
              :fields="tableFields"
              head-color="light"
              column-filter
              table-filter
              sorter
              :tableFilter="{'label':'Поиск','placeholder':'Введите слово для поиска'}"
          >

            <td slot="price_per_kg" slot-scope="{ item,index }">
              <CInput
                  :value.sync="item.price_per_kg"
                  custom
                  inline
                  @input="changeItem(item,index)"
                  @change="changeItem(item,index)"
              />
            </td>
            <td slot="file_city" slot-scope="{ item,index }">
              <CInput
                  :value.sync="item.file_city"
                  custom
                  inline
              />
            </td>
            <td slot="price_per_kg" slot-scope="{ item,index }">
              <CInput
                  :value.sync="item.price_per_kg"
                  custom
                  inline
                  @input="changeItem(item,index)"
                  @change="changeItem(item,index)"
              />
            </td>
            <td slot="price_per_place" slot-scope="{ item,index }">
              <CInput
                  :value.sync="item.price_per_place"
                  custom
                  inline
                  @input="changeItem(item,index)"
                  @change="changeItem(item,index)"
              />
            </td>
            <td slot="action" slot-scope="{ item,index }">
              <div style="display: flex">
                <CButton color="danger" @click="deleteItem(item,index)">
                  Удалить
                </CButton>
              </div>
            </td>
          </CDataTable>
          <div class="d-flex aligin-items-center justify-content-between my-4">
            <div></div>
            <CButton color="success" @click="uploadToArray()">
              Сохранить
            </CButton>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
    <div class="alert_block">
      <CAlert color="warning" closeButton v-if="warning">
        {{ alert_message }}
      </CAlert>
      <CAlert color="primary" closeButton v-if="success">
        {{ alert_message }}
      </CAlert>
    </div>
  </CRow>
</template>

<script>
export default {
  name: "MasterHubs",
  data() {
    return {
      all_sum_wallet: '',
      all_sum_counter: '',
      all_sum_weight: '',
      file_type: '',
      file_city: '',
      race_number: '',
      warning: false,
      success: false,
      alert_message: '',
      warningModal: false,
      isSelect: false,
      action_switcher: '',
      action_switcher_city: '',
      id: "",
      file_path: '',
      fileData: '',
      variables: '',
      tableItems: [],
      tableFields: [
        {key: "code", label: "Код"},
        {key: "name", label: "Название"},
        {key: "phone1", label: "Телефон 1"},
        {key: "phone2", label: "Телефон 2"},
        {key: "action", label: "Действия"},
      ],
    };
  },
  mounted() {
  },
  methods: {

    deleteItem(item, index) {
      let app = this;
      app.tableItems.splice(index, 1);
    },
    onFileChange() {
      this.file_path = event.target.files[0];
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.fileData = e.target.result;
        }
        reader.readAsDataURL(input.files[0]);
      }
    },
    uploadToArray() {
      event.preventDefault();
      let app = this;
      axios.post('/auth/contacts/add-list', {data: app.tableItems})
          .then(({data}) => {
            app.success = true;
            app.interval = 0.5;
            app.alert_message = 'Данные успешно сохранены';
            setTimeout(function () {
              app.success = false;
            }, 3000);
            app.tableItems = [];
          }).catch(function (error) {

      });
    },
    convertToArray() {
      event.preventDefault();
      let app = this;
      app.loader = true;
      const config = {
        headers: {'content-type': 'multipart/form-data'}
      }
      let formData = new FormData();
      formData.append('file', app.file_path);
      formData.append('file_type', app.file_type);
      formData.append('file_city', app.file_city);
      formData.append('race_number', app.race_number);
      axios.post('/auth/convertor-clients/excel-to-json', formData, config).then(function (response) {
        app.success = true;
        app.tableItems = response.data.array;
        app.alert_message = 'Данные за парсены';
        setTimeout(function () {
          app.success = false;
        }, 3000)
      }).catch(function (error) {
      })
    },
    showErrors: function (errors) {
      let app = this;
      this.errors = errors;
      setTimeout(function () {
        app.errors = {};
      }, 3000)
    },
    showSuccess: function (message) {
      let app = this;
      app.success = message.data.message;
      setTimeout(function () {
        app.success = '';
      }, 3000)
    },

  },
};
</script>
<style>
.clapans .form-inline {
  margin-left: 15px;
}

.alert_block {
  position: fixed;
  right: 0;
  bottom: 0;
}
</style>
Œ
