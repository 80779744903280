var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"md":"12"}},[_c('CCard',{staticClass:"clapans"},[_c('CCardHeader',[_vm._v(" "+_vm._s(_vm.alert_message)),_c('br'),_c('div',{staticClass:"d-flex aligin-items-center justify-content-between"},[_c('div',{staticClass:"input-group mb-3"},[_c('div',{staticClass:"form-file"},[_c('input',{staticClass:"form-file-input",attrs:{"type":"file","id":"inputGroupFile01","aria-describedby":"inputGroupFileAddon01"},on:{"change":_vm.onFileChange}})])]),_c('CButton',{attrs:{"color":"success"},on:{"click":function($event){return _vm.convertToArray()}}},[_vm._v(" Добавить ")])],1)]),_c('CCardBody',[_c('CDataTable',{staticClass:"mb-0 table-outline",attrs:{"hover":"","items":_vm.tableItems,"fields":_vm.tableFields,"head-color":"light","column-filter":"","table-filter":"","sorter":"","tableFilter":{'label':'Поиск','placeholder':'Введите слово для поиска'}},scopedSlots:_vm._u([{key:"price_per_kg",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{},[_c('CInput',{attrs:{"value":item.price_per_kg,"custom":"","inline":""},on:{"update:value":function($event){return _vm.$set(item, "price_per_kg", $event)},"input":function($event){return _vm.changeItem(item,index)},"change":function($event){return _vm.changeItem(item,index)}}})],1)}},{key:"file_city",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{},[_c('CInput',{attrs:{"value":item.file_city,"custom":"","inline":""},on:{"update:value":function($event){return _vm.$set(item, "file_city", $event)}}})],1)}},{key:"price_per_place",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{},[_c('CInput',{attrs:{"value":item.price_per_place,"custom":"","inline":""},on:{"update:value":function($event){return _vm.$set(item, "price_per_place", $event)},"input":function($event){return _vm.changeItem(item,index)},"change":function($event){return _vm.changeItem(item,index)}}})],1)}},{key:"action",fn:function(ref){
var item = ref.item;
var index = ref.index;
return _c('td',{},[_c('div',{staticStyle:{"display":"flex"}},[_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){return _vm.deleteItem(item,index)}}},[_vm._v(" Удалить ")])],1)])}}])}),_c('div',{staticClass:"d-flex aligin-items-center justify-content-between my-4"},[_c('div'),_c('CButton',{attrs:{"color":"success"},on:{"click":function($event){return _vm.uploadToArray()}}},[_vm._v(" Сохранить ")])],1)],1)],1)],1),_c('div',{staticClass:"alert_block"},[(_vm.warning)?_c('CAlert',{attrs:{"color":"warning","closeButton":""}},[_vm._v(" "+_vm._s(_vm.alert_message)+" ")]):_vm._e(),(_vm.success)?_c('CAlert',{attrs:{"color":"primary","closeButton":""}},[_vm._v(" "+_vm._s(_vm.alert_message)+" ")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }